@font-face {
	font-family: "Object Sans";
	src: url("../assets/fonts/ObjectSans-Regular.otf");
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "Object Sans";
	src: url("../assets/fonts/ObjectSans-Heavy.otf");
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "Apercu Pro";
	src: url("../assets/fonts/ApercuPro-Regular.otf");
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "Apercu Pro";
	src: url("../assets/fonts/ApercuPro-Bold.otf");
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "Canela";
	src: url("../assets/fonts/Canela-Medium.otf");
	font-weight: normal;
	font-display: swap;
}
@font-face {
	font-family: "Canela";
	src: url("../assets/fonts/Canela-Black.otf");
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "Northwell";
	src: url("../assets/fonts/Northwell.otf");
	font-weight: normal;
	font-display: swap;
}
