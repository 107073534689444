@import "./reset.css";
@import "./bootstrap-grid.min.css";
@import "./fonts.css";

/* Main */

:root {
    font-family: "Apercu Pro", Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;
    height: 100%;
    min-height: 100%;
    font-display: swap;
    /* Theme variables. */
    --primaryColor: #ffffff;
    --secondaryColor: #ffe6f4;
    --backgroundColor: #000000;
    --placeholderColor: #5c5c67;
    background-color: var(--backgroundColor);
    color: var(--primaryColor);
}

html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    border: 0;
}

body {
    position: relative;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    scroll-snap-type: y proximity;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding-left: 8px;
    border: 0;
}

strong {
    font-weight: bold;
}

* {
    font-family: "Apercu Pro", Arial, Helvetica, sans-serif;
    color: var(--primaryColor);
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    line-height: 1;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    /* outline: 1px solid var(--secondaryColor); */
    outline: 0;
}

*:focus {
    outline-color: var(--secondaryColor);
}


/* Links  */

a {
    text-decoration: none;
    font-weight: bold;
    color: var(--primaryColor);
    transition: color 0.3s;
}

a:hover {
    color: var(--secondaryColor);
}

.link {
    font-family: "Object Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.015em;
    color: var(--primaryColor);
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .link {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1440px) {
    .link {
        font-size: 18px;
        line-height: 27px;
    }
}

@media (min-width: 1920px) {
    .link {
        font-size: 24px;
        line-height: 36px;
    }
}


/* Headers */

.h1 {
    font-family: "Object Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: var(--primaryColor);
    font-size: 36px;
    line-height: 47px;
}

@media (min-width: 768px) {
    .h1 {
        font-size: 48px;
        line-height: 62px;
    }
}

@media (min-width: 1440px) {
    .h1 {
        font-size: 72px;
        line-height: 94px;
    }
}

@media (min-width: 1920px) {
    .h1 {
        font-size: 86px;
        line-height: 110px;
        font-size: 86px;
        line-height: 108px;
    }
}

.h2 {
    font-family: "Object Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: var(--primaryColor);
    font-size: 24px;
    line-height: 36px;
}

@media (min-width: 768px) {
    .h2 {
        font-size: 28px;
        line-height: 36px;
    }
}

@media (min-width: 1440px) {
    .h2 {
        font-size: 54px;
        line-height: 70px;
    }
}

@media (min-width: 1920px) {
    .h2 {
        font-size: 4.5rem;
        /* 72px */
        line-height: 5.375rem;
        /* 86px */
    }
}

.h3 {
    font-family: "Object Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.015em;
    text-transform: uppercase;
    color: var(--primaryColor);
}

@media (min-width: 768px) {
    .h3 {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (min-width: 1440px) {
    .h3 {
        font-size: 36px;
        line-height: 46px;
    }
}

@media (min-width: 1920px) {
    .h3 {
        font-size: 3rem;
        /* 48px */
        line-height: 4.5rem;
        /* 72px */
    }
}

.h4 {
    font-family: "Canela", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.015em;
    color: var(--primaryColor);
    font-size: 22px;
    line-height: 29px;
}

@media (min-width: 768px) {
    .h4 {
        font-size: 24px;
        line-height: 31px;
    }
}

@media (min-width: 1440px) {
    .h4 {
        font-size: 48px;
        line-height: 63px;
    }
}

@media (min-width: 1920px) {
    .h4 {
        font-size: 4.5rem;
        line-height: 5.875rem;
        /* 94px */
    }
}


/* Texts */

.p {
    font-family: "Apercu Pro", Arial, Helvetica, sans-serif;
    letter-spacing: 0.015em;
    color: var(--primaryColor);
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .p {}
}

@media (min-width: 1440px) {
    .p {
        font-size: 18px;
        line-height: 27px;
    }
}

@media (min-width: 1920px) {
    .p {
        font-size: 24px;
        line-height: 36px;
    }
}

.accented-text {
    color: var(--secondaryColor) !important;
}

.wrapped-words-text {
    word-spacing: 100vw;
    /* wrap each word */
}

.handwritten-text {
    font-family: "Northwell";
    text-transform: capitalize;
    font-style: normal;
    font-weight: normal;
    font-size: 17.0839px;
    line-height: 28px;
}

@media (min-width: 768px) {
    .handwritten-text {
        font-size: 32px;
        line-height: 52px;
    }
}

@media (min-width: 1440px) {
    .handwritten-text {
        font-size: 42px;
        line-height: 68px;
    }
}

@media (min-width: 1920px) {
    .handwritten-text {
        font-size: 42px;
        line-height: 68px;
    }
}

.strong-text {
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.015em;
    /* text-transform: uppercase; */
    color: var(--primaryColor);
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .strong-text {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (min-width: 1440px) {
    .strong-text {
        font-size: 26px;
        line-height: 34px;
    }
}

@media (min-width: 1920px) {
    .strong-text {
        font-size: 36px;
        line-height: 36px;
    }
}

.upper-text {
    text-transform: uppercase;
}

.copiright-text {
    font-family: "Object Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.015em;
}

@media (min-width: 768px) {
    .copiright-text {
        font-size: 10px;
    }
}

@media (min-width: 1440px) {
    .copiright-text {
        font-size: 14px;
    }
}

@media (min-width: 1920px) {
    .copiright-text {
        font-size: 18px;
    }
}


/* Buttons */

.button {
    border: 1px solid var(--primaryColor);
    -webkit-appearance: none;
    white-space: nowrap;
    background-color: transparent;
    color: var(--primaryColor);
    transition: all 0.25s linear;
    font-family: "Apercu Pro";
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    color: var(--primaryColor);
    cursor: pointer;
}

.button:hover {
    background-color: var(--primaryColor);
}

.button-disabled {
    background-color: var(--placeholderColor) !important;
    color: var(--backgroundColor) !important;
}

.button:hover,
.button:hover * {
    color: var(--backgroundColor);
}

.button {
    padding-left: 28px;
    padding-right: 28px;
}

@media (min-width: 768px) {
    .button {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1440px) {
    .button {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media (min-width: 1920px) {
    .button {
        padding-left: 88px;
        padding-right: 88px;
    }
}

.form__field {
    color: var(--primaryColor);
    max-height: 45px;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.form__field::placeholder {
    color: var(--placeholderColor);
}

input.form__field {
    border-bottom: 1px solid white;
}

@media (min-width: 768px) {
    .form__field {
        max-height: 48px;
        font-size: 14px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (min-width: 1440px) {
    .form__field {
        max-height: 66px;
        min-height: 66px;
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (min-width: 1920px) {
    .form__field {
        font-size: 28px;
        padding-top: 26px;
        padding-bottom: 26px;
        min-height: 88px;
        max-height: 88px;
    }
}


/* Other */

hr {
    border-top: 1px solid var(--primaryColor);
    opacity: 1;
}

.section {
    position: relative;
    scroll-snap-align: center;
    height: 100vh;
    min-height: 100vh;
}

.footer {
    border-top: 1px solid var(--placeholderColor);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 43px;
}

@media (min-width: 768px) {
    .footer {
        padding: 46px;
    }
}

@media (min-width: 1440px) {
    .footer {
        padding: 77px;
    }
}

@media (min-width: 1920px) {
    .footer {
        padding: 115px;
    }
}

.page::after {
    content: "";
    background-image: url("../assets/images/background.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.centered {
    text-align: center;
}

.visually-hidden {
    overflow: hidden !important;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
}

.modal {
    position: fixed;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 80vw;
    padding: 10vh 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryColor);
}

.modal__message {
    font-family: Apercu Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 100% */
    letter-spacing: 0.015em;
    color: var(--backgroundColor);
}

@media (min-width: 768px) {
    .modal__message {
        font-size: 20px;
        line-height: 28px;
    }
}

@media (min-width: 1440px) {
    .modal__message {
        font-size: 28px;
        line-height: 36px;
    }
}

@media (min-width: 1920px) {
    .modal__message {
        font-size: 36px;
        line-height: 48px;
    }
}

.modal__close::after {
    content: "✕";
    font-family: Apercu Pro;
    font-size: 24px;
    line-height: 24px;
    color: var(--backgroundColor);
    position: absolute;
    display: block;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}